<template>
  <form id="frm_action">
    <div class="p-formgrid">
      <div >
        <div >
          <label class="txt-right">Tên hạng mục chi phí <span class="span-cus">*</span></label>
          <!-- <div class="p-col"> -->
          <Textarea
            v-model="name"
            :autoResize="true"
            rows="1"
            maxlength="255"
            class="text-box input-text-box"
            placeholder="Nhập"
          />
          <input class="input_tmp_validator" id="name" />
          <!-- </div> -->
        </div>
        <div >
          <label class="txt-right mt-3">Mã dịch vụ <span class="span-cus">*</span></label>
          <Textarea
            v-model="code"
            :autoResize="true"
            rows="1"
            maxlength="50"
            class="text-box input-text-box"
            placeholder="Nhập"
          />
          <input class="input_tmp_validator" id="code" />
        </div>
        <div>
          <label class="txt-right mt-3">Phân loại vận chuyển <span class="span-cus">*</span></label>
          <v-select :clearable="false" v-model="selClassify" :options="listClassify" :get-option-label="(option) => option.label" class="text-box" placeholder="Chọn" />
          <input class="input_tmp_validator" id="classify_id">
        </div>
        
        <div>
          <div class="p-inputgroup col-lg-6 col-12 p-0 mt-3">
            <div class="pr-2 width-70">
              <label class="txt-left">Loại phương tiện <span class="span-cus">*</span></label>
              <v-select :clearable="false" v-model="selTypeVehicle" :disabled="typeVehicleDisable" :options="listTypeVehicle" :get-option-label="(option) => option.label" class="text-box pr-2 w-100" placeholder="Chọn" />
              <input class="input_tmp_validator" id="type_vehicle_id">
            </div>

            <div class="pl-2 width-30">
              <label class="txt-left">Đơn vị tính <span class="span-cus">*</span></label>
              <v-select :clearable="false" disabled="disabled" v-model="selUnit" :options="listUnit" @search="fetchOptionsUnit" :get-option-label="(option) => option.name" class="text-box no-arrow w-100"/>
              <input class="input_tmp_validator" id="unit_id">
            </div>
          </div>
        </div>
        
        <div>
          <label class="txt-right mt-3">Khu vực giao hàng <span class="span-cus">*</span></label>
          <v-select :clearable="false" v-model="selTypeArea" :options="listTypeArea" @search="fetchOptionsTypeArea" :get-option-label="(option) => option.name" class="text-box" placeholder="Chọn" />
          <input class="input_tmp_validator" id="area_type_id">
        </div>
        <ShippingMethodPrice :mode="mode" :data="shipping_method_prices" />
        <input class="input_tmp_validator" id="shipping_method_price_id">
        <div class="flex justify-content-center mt-3">
          <Checkbox v-model="active" :binary="true" /> Hiển thị
        </div>
      </div>

      <Toolbar class="fixed-bottom-toolbar">
        <template slot="right">
          <Button
            :label="$constants.TEXT_BUTTON.BACK"
            @click="backToList()"
            class="p-button-warning callback-btn"
          />
          <Button
            v-if="mode == 'edit'"
            :label="$constants.TEXT_BUTTON.EDIT"
            @click="saveData()"
            class="main-style-button"
          />
          <Button
            v-else
            :label="$constants.TEXT_BUTTON.ADD"
            @click="saveData()"
            class="main-style-button"
          />
        </template>
      </Toolbar>
      <Dialog
          :key="keyPopup"
          :visible.sync="showDuplicatedDialog"
          :contentStyle="{ overflow: 'visible' }"
          :modal="true"
        >
        Đã tồn tại đơn giá dịch vụ logistics trước đó, vui lòng kiểm tra lại!
          <template #footer>
            <Button
              label="Xác nhận"
              @click="showDuplicatedDialog = false"
              icon="pi pi-check"
              class="p-button-success main-style-button"
            />
          </template>
        </Dialog>
    </div>
  </form>
</template>

<script>
import DataServices from "@/core/DataServices";
import ShippingMethodPrice from "../ShippingMethodPrice";
// import gql from "graphql-tag";

export default {
  props: {
    mode: String,
    model: Object,
    users: Array,
  },
  components: {
    ShippingMethodPrice,
  },
  data() {
    return {
      keyPopup: false,
      shipping_method_prices: [],
      code: "",
      name: "",
      unit_id: null,
      selUnit: null,
      selTypeArea: null,
      ordinal: null,
      active: true,
      formMode: this.mode,
      list_group_role: [],
      saving: false,
      listUnit: [],
      listUnitAll: [],
      listTypeArea: [],
      listTypeAreaAll: [],
      listClassify: [
          {value: 1, label: 'Vận chuyển thường'},
          {value: 2, label: 'Hàng trả về không kết hợp với chuyến đi giao cùng địa điểm'},
          {value: 3, label: 'Hàng trả về có kết hợp với chuyến đi giao cùng địa điểm'},
          {value: 4, label: 'Giao bằng xe tải từ điểm giao thứ 2'},
          {value: 5, label: 'Vận chuyển hàng hoá ghép chuyến với hàng của Công ty'}
      ],
      listTypeVehicle: [
          {value: 1, label: 'Xe tải'},
          {value: 2, label: 'Xe máy'}
      ],
      selTypeVehicle: null,
      selClassify: null,
      typeVehicleDisable: false,
      showDuplicatedDialog: false,
    };
  },
  watch: {
    'selTypeVehicle'() {
      console.log("watched changed vehicle");
      this.ChangeVehical();
    },
    'selClassify'() {
      if (this.selClassify?.value === 4) {
        this.selTypeVehicle = {value: 1, label: 'Xe tải'};
        this.typeVehicleDisable = true
      } else {
        this.typeVehicleDisable = false
      }
    }
  },
  async mounted() {
    await this.getListUnit();
    await this.getlistTypeArea();
  },
  async created() {
    if (this.$route.params.id !== undefined && this.$route.params.id !== null) {
      await this.getData(this.$route.params.id);
    }
  },
  methods: {
    async ChangeVehical() {
      console.log("In change vehicle", this.selTypeVehicle);
      if (!this.$commonFuction.isEmptyObject(this.selTypeVehicle)) {
        console.log("day la value", this.selTypeVehicle.value);
        var name = this.selTypeVehicle?.value == 1 ? 'Chuyến': 'Đơn hàng';
        console.log("THis is the filter name", name);
        var where_key = {
          deleted : {_eq : false},
          name : {_eq : name},
        }
        console.log("This is the filter param", where_key.name); 
        let {data} = await this.$apollo.query({
          query: DataServices.getList('unit',{"fields" : `id name`}),
          variables: {
            where_key: where_key,
            orderBy:[{created_at : 'desc'}],
            limit: 1
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        if (list.length < 1) {
          this.addUnit(name);
        } else {
          console.log("List unit", list);
          console.log("choosing unit", this.selUnit);
          this.selUnit = {"id": list[0].id, "name": list[0].name}
          console.log("chosen unit", this.selUnit);
        }
      } else {
        this.selUnit = null;
      }
      console.log("still in change vehicle", this.selTypeVehicle);
    },
    async addUnit(name) {
      if (!this.$commonFuction.isEmpty(name)) {
        await this.$apollo.mutate({
            mutation: DataServices.insertData('unit'),
            variables: {
              objects: {"name": name}
            }
        }).then(async (res) => {
            if (res.data[Object.keys(res.data)].affected_rows) {
              var returning = res.data[Object.keys(res.data)].returning[0];
              this.selUnit = {"id": returning.id, "name": name}
              await this.getListUnit();
            }
        }).catch((e)=> {
          console.log(e);
        })
      }
    },
    async getListUnit(){
      var where_key = {
        deleted : {_eq : false}
      }
      let {data} = await this.$apollo.query({
        query: DataServices.getList('unit',{"fields" : `id name`}),
        variables: {
          where_key: where_key,
          orderBy:[{created_at : 'desc'}]
        },
        fetchPolicy: "network-only"
      })
      var list = data[Object.keys(data)];
      var list_unit = [];
      // console.log("list===",list);
      for (let i = 0; i < list.length; i++) {
        const e = list[i];
        list_unit.push({id : e.id, name :e.name});
      }
      this.listUnit = list_unit;
      this.listUnitAll = list_unit;
    },
    async fetchOptionsUnit(search, loading){
      console.log(loading);
      if(search !== '' && search !== null){
        var where_key = {
          // plain_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
          deleted : {_eq : false},
          name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
        };
        let {data} = await this.$apollo.query({
          query: DataServices.getList('unit',{"fields" : `id name`}),
          variables: {
            where_key: where_key,
            orderBy:[{created_at : 'desc'}]
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_unit = [];
        // console.log("list===",list);
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          list_unit.push({id : e.id, name :e.name});
        }
        this.listUnit = list_unit;
      }else{
        // cosnole.log("dasdasd")
        this.listUnit = this.listUnitAll
      }
      return this.listUnit;
    },
    
    async getlistTypeArea(){
      var where_key = {
        deleted : {_eq : false}
      }
      let {data} = await this.$apollo.query({
        query: DataServices.getList('area_type',{"fields" : `id name`}),
        variables: {
          where_key: where_key,
          orderBy:[{created_at : 'desc'}]
        },
        fetchPolicy: "network-only"
      })
      var list = data[Object.keys(data)];
      var list_sr = [];
      // console.log("list===",list);
      for (let i = 0; i < list.length; i++) {
        const e = list[i];
        list_sr.push({id : e.id, name :e.name});
      }
      this.listTypeArea = list_sr;
      this.listTypeAreaAll = list_sr;
    },
    async fetchOptionsTypeArea(search, loading){
      console.log(loading);
      if(search !== '' && search !== null){
        var where_key = {
          // plain_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
          deleted : {_eq : false},
          name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
        };
        let {data} = await this.$apollo.query({
          query: DataServices.getList('area_type',{"fields" : `id name`}),
          variables: {
            where_key: where_key,
            orderBy:[{created_at : 'desc'}]
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_sr = [];
        // console.log("list===",list);
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          list_sr.push({id : e.id, name :e.name});
        }
        this.listTypeArea = list_sr;
      }else{
        // cosnole.log("dasdasd")
        this.listTypeArea = this.listTypeAreaAll
      }
      return this.listTypeArea;
    },
    backToList() {
      this.$emit("back");
    },
    async checkCode(code) {
      var where_key = {
          // plain_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
          deleted : {_eq : false},
          type: {_eq: this.$constants.SHIPPING_METHOD_LOGICTICS},
          code:{_ilike: "%"+this.$commonFuction.SkipVN(code).trim()+"%"},
          id: {_neq: this.$route.params.id},
        };
        let {data} = await this.$apollo.query({
          query: DataServices.getList('shipping_method',{"fields" : `id name code`}),
          variables: {
            where_key: where_key,
            orderBy:[{created_at : 'desc'}]
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_sr = [];
        // console.log("list===",list);
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          list_sr.push({id : e.id, name :e.name});
        }
        if (list_sr.length > 0) {
            return true;
        }
        return false;
    },
    
    async validateData() {
      this.validationErrors = {};

      let name_add = document.getElementById("name");
      if (
        this.name == undefined ||
        this.name == null ||
        this.name == ""
      ) {
        name_add.setCustomValidity("Tên hạng mục không được để trống!");
      } else {
        name_add.setCustomValidity("");
      }
      
      let code_add = document.getElementById("code");
      if (
        this.code == undefined ||
        this.code == null ||
        this.code == ""
      ) {
        code_add.setCustomValidity("Mã dịch vụ không được để trống!");
      } else {
        var check = await this.checkCode(this.code);
        if (check) {
          code_add.setCustomValidity(`Mã dịch vụ ${this.code} đã tồn tại!`);
        } else {
          code_add.setCustomValidity("");
        }
      }
      
      let classify_add = document.getElementById("classify_id");
      if (
        this.$commonFuction.isEmptyObject(this.selClassify)
      ) {
        classify_add.setCustomValidity("Phân loại vận chuyển không được để trống!");
      } else {
        classify_add.setCustomValidity("");
      }


      let type_vehicle_add = document.getElementById("type_vehicle_id");
      if (
        this.$commonFuction.isEmptyObject(this.selTypeVehicle)
      ) {
        type_vehicle_add.setCustomValidity("Loại phương tiện không được để trống!");
      } else {
        type_vehicle_add.setCustomValidity("");
      }
      
      let unit_add = document.getElementById("unit_id");
      if (
        this.$commonFuction.isEmptyObject(this.selUnit)
      ) {
        unit_add.setCustomValidity("Đơn vị tính không được để trống!");
      } else {
        unit_add.setCustomValidity("");
      }
      
      let area_type_add = document.getElementById("area_type_id");
      if (
        this.$commonFuction.isEmptyObject(this.selTypeArea)
      ) {
        area_type_add.setCustomValidity("Khu vực giao hàng không được để trống!");
      } else {
        area_type_add.setCustomValidity("");
      }
      
      let shipping_method_price_add = document.getElementById("shipping_method_price_id");
      //reassign shipping_method_prices by eliminating element with empty selCompany and price
      this.shipping_method_prices = this.shipping_method_prices.filter( m => { return (!this.$commonFuction.isEmptyObject(m.selCompany) || !this.$commonFuction.isEmpty(m.price))});
      if (
        this.shipping_method_prices.length < 1
      ) {
        shipping_method_price_add.setCustomValidity("Công ty hoặc đơn giá không được để trống!");
      } else {
        var check_price  = this.shipping_method_prices.filter( m => { return (!this.$commonFuction.isEmptyObject(m.selCompany) || !this.$commonFuction.isEmpty(m.selCompany)) && !this.$commonFuction.isEmpty(m.price) })
        // check_price stores the list of shipping_method_prices that has only one field filled in
        
        if (check_price.length != this.shipping_method_prices.length) {
          shipping_method_price_add.setCustomValidity("Công ty hoặc đơn giá không được để trống!");
        } else {
          shipping_method_price_add.setCustomValidity("");
        }
      }

      var inpObj = document.getElementById("frm_action");
      if (!inpObj.checkValidity()) {
        console.log("test=====================");
        inpObj.reportValidity();
        return true;
      }
    },
    async checkDuplicatedService() {
      //get data from shipping_method table and check if there are duplicated {classify, type_vehicle, area_type, selcompany}
      var where_key = {
        deleted : {_eq : false},
        type: {_eq: this.$constants.SHIPPING_METHOD_LOGICTICS},
        //type_vehicle: {_eq: this.selTypeVehicle},
        classify: {_eq: this.selClassify?.value},
        type_area: {_eq: this.selTypeArea?.id},
      };
      //console.log("this.selTypeVehicle:",this.selTypeVehicle)
      if (this.selTypeVehicle.value){
        where_key.type_vehicle= {_eq: this.selTypeVehicle.value};
        //where_key.type_vehicle= {_eq: this.selTypeVehicle[0]?.value};
      }
      console.log("Here comes vehicle", this.selTypeVehicle);
      if (this.mode == 'edit') {
        where_key.id = {_neq: this.$route.params.id}
      }
      //where shipping_method_prices.company_id is in the list of selCompany.id
      //
      where_key.shipping_method_prices = {company_id: {_in: this.shipping_method_prices.map(m => {return m.selCompany?.id})}};
      let {data} = await this.$apollo.query({
        // query: DataServices.getList('shipping_method',{"fields" : `id`}),
        query: DataServices.getList('shipping_method',{"fields" : `id`}),
        variables: {
          where_key: where_key,
          orderBy:[{created_at : 'desc'}]
        },
        fetchPolicy: "network-only"
      })
      var list = data[Object.keys(data)];
      console.log("LMAOOO, this is the list", list, list.length);
      if (list.length > 0) {
        return true;
      }
      return false;
    },
    async saveData() {
      // console.log(this.shipping_method_prices);
      this.$commonFuction.is_loading(true);
      if (await this.checkDuplicatedService()) {
        this.showDuplicatedDialog = true;
        this.$commonFuction.is_loading(false);
        return;
      }
      if (await this.validateData()) {
        // this.saving = false;
        this.$commonFuction.is_loading(false);
        return;
      }

      if (Object.entries(this.validationErrors).length !== 0)
        this.$commonFuction.is_loading(false);

      // if (!this.account_exist) {
      var params = {
        shipping_method_prices: this.shipping_method_prices.map(e => {return {company_id: e.selCompany?.id, price: e.price}}),
        code: this.code,
        name: this.name,
        unit_id: this.selUnit?.id || null,
        type_area: this.selTypeArea?.id || null,
        type_vehicle: this.selTypeVehicle?.value || this.selTypeVehicle[0]?.value,
        classify: this.selClassify?.value || this.selClassify[0]?.value,
        active: this.active,
      };
      console.log("Incoming params", params);
      this.$emit("save", params);
      // }
    },
    async getData(id) {
      var { data } = await this.$apollo.query({
        query: DataServices.getList("shipping_method_logictics", {"field": "id name code ordinal classify type_vehicle shipping_method_prices{id price company{id code}} area_type{id name} unit{id name}"}),
        variables: {
          where_key: {
            id: { _eq: id },
            type: {_eq: this.$constants.SHIPPING_METHOD_LOGICTICS}
          },
        },
        fetchPolicy: "network-only",
      });
      var model = data[Object.keys(data)];
      console.log("Get data", model[0]);
      if (model.length > 0) {
        this.models = model[0];
        this.selUnit = {id: this.models.unit?.id, name: this.models.unit?.name} || null;
        console.log("Model unit", this.selUnit);
        this.selTypeArea = {id: this.models.area_type?.id, name: this.models.area_type?.name} || null;
        this.selClassify = this.listClassify.filter(e => { return e.value == this.models.classify })[0]
        console.log("Before change vehicle", this.listTypeVehicle);
        this.selTypeVehicle = this.listTypeVehicle.filter(e => { return e.value == this.models.type_vehicle })[0]
        console.log("After change vehicle", this.selTypeVehicle);
        
        this.shipping_method_prices = this.models?.shipping_method_prices.map(m => { return {selCompany: {id: m.company?.id, code: m.company?.code}, price: m.price}}) || [];
        this.name = this.models.name;
        this.code = this.models.code;
        this.active = this.models.active;
        this.ordinal = this.models.ordinal;
      }
      this.componentKey++;
    }
    
  },
  /*apollo: {
        category: {
          query() {
            return DataServices.getList('category', {"fields" : "id name"});
          },
          update(data) {
            if(data.category){
              this.categories = data.category
            }
          },
          fetchPolicy: "network-only"
        },
      }*/
};
</script>

<style lang="scss">
.p-multiselect {
  width: 450px;
}
.p-multiselect-label:not(.p-placeholder) {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.category-item-value {
  padding: 0.25rem 0.5rem;
  border-radius: 3px;
  display: inline-flex;
  margin-right: 0.2rem;
  background-color: var(--primary-color);
  color: var(--primary-color-text);
}
.p-multiselect-label-container {
  height: 40px;
}

label {
  font-weight: bold;
}

.radio-option {
  margin-bottom: 0 !important;
}
.required:after {
  content: " *";
  color: red;
}
.no-arrow{
  .vs__actions{
    display: none !important;
  }
}
.width-70{
  width: 70% !important;
}
.width-30{
  width: 30% !important;
}
@media (max-width: 960px){
  .width-77{
    width: 55% !important;
  }
  .width-23{
    width: 45% !important;
  }
}
</style>