<template>
    <div class="col-lg-6 col-12 p-0">
        <div class="row">
            <div class="col-5">
                <label class="txt-right mt-3">Mã công ty <span class="span-cus">*</span></label>
            </div>
            <div class="col-7">
                <label class="txt-right mt-3">Đơn giá <span class="span-cus">*</span></label>
            </div>
        </div>
        <div class="row mb-3" v-for="(item, index) in data" :key="index">
            <div class="col-5">
                <v-select :clearable="false" v-model="item.selCompany" :options="listCompany"  :get-option-label="(option) => option.code" placeholder="Chọn" @search="(search, loading) => { fetchOptionsFreight(search, loading, index) }" @open="getListCompany(index)"/>
                <input class="input_tmp_validator" :id="'company_id_'+index">
            </div>
            <div class="col-7 ">
                <div class="p-inputgroup">
                    <InputNumber
                        :min="0"
                        v-model="item.price"
                        id="price"
                        mode="decimal" :minFractionDigits="0" 
                        placeholder="Nhập"
                    />
                    <span class="p-inputgroup-addon rounded" style="border: 1px solid #D0D4D9 !important">
                      đ
                    </span>
                    <Button
                      type="button"
                      icon="pi pi-trash"
                      class="ml-2 p-button-danger px-4 rounded"
                      @click="delRow(index)"
                      severity="danger"
                    />
                </div>
            </div>
        </div>
        
        <a class="text-primary-new pointer" @click="AddRow">Thêm mới</a>
    </div>
</template>
<script>
import DataServices from "@/core/DataServices";
export default {
  props: {
    mode: String,
    data: Array,
  },
  data() {
    return {
        listCompany: [],
        listCompanyAll: [],
    }
  },
  async created() {
    await this.getListCompany();
    console.log("meomeomeo", this.mode, this.data);
    if (this.mode == 'add' && this.data.length == 0)
        this.AddRow();
  },
  methods: {
    async getListCompany(index=null){
      var list_ids = [];
      if (index != null) {
          list_ids = this.data.filter((e) => !this.$commonFuction.isEmptyObject(e.selCompany?.id) && index && this.data[index]?.selCompany?.id != e.selCompany?.id);
      } else {
          list_ids = this.data.filter((e) => !this.$commonFuction.isEmptyObject(e.selCompany?.id));
      }
      var ids = list_ids.map(e => {return e.selCompany?.id;});

      var where_key = {
        deleted : {_eq : false},
        id: {_nin: ids},
      }
      let {data} = await this.$apollo.query({
        query: DataServices.getList('company',{"fields" : `id code`}),
        variables: {
          where_key: where_key,
          orderBy:[{code : 'asc'}]
        },
        fetchPolicy: "network-only"
      })
      var list = data[Object.keys(data)];
      var list_company = [];
      // console.log("list===",list);
      for (let i = 0; i < list.length; i++) {
        const e = list[i];
        list_company.push({id : e.id, code :e.code});
      }
      console.log("list_company===", list_company);
      this.listCompany = list_company;
      this.listCompanyAll = list_company;
      console.log("All companies", this.listCompanyAll);
    },
    async fetchOptionsCompany(search, loading, index=null){
      console.log(loading);
      console.log('search',search);
      if(search !== '' && search !== null){
        var list_ids = [];
        if (index != null) {
            list_ids = this.data.filter((e) => !this.$commonFuction.isEmptyObject(e.selCompany?.id) && index && this.data[index]?.selCompany?.id != e.selCompany?.id);
        } else {
            list_ids = this.data.filter((e) => !this.$commonFuction.isEmptyObject(e.selCompany?.id));
        }
        var ids = list_ids.map(e => {return e.selCompany?.id;});
        var where_key = {
          // plain_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
          deleted : {_eq : false},
          id: {_nin: ids},
          code:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
        };
        let {data} = await this.$apollo.query({
          query: DataServices.getList('company',{"fields" : `id code`}),
          variables: {
            where_key: where_key,
            orderBy:[{created_at : 'desc'}]
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_company = [];
        // console.log("list===",list);
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          list_company.push({id : e.id, name :e.name});
        }
        console.log("list_company===fetch", list_company);
        this.listCompany = list_company;
      }else{
        // cosnole.log("dasdasd")
        this.listCompany = this.listCompanyAll
      }
    },
    
    async AddRow() {
        this.data.push({
            selCompany: null,
            price: null,
        });
    },
    
    async delRow(index) {
      this.data.splice(index, 1);
    }
    
  }
}
</script>
